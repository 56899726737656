<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        Payment Page
      </h3>
    </portal>

  </div>
</template>

<script>

export default {
  name: 'RentalSalePayment',
}
</script>

<style scoped lang="scss"></style>
